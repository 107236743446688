
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import ClPaymentNew from '~/components/common/cl_payment/New'
import { scrollToTop } from '~/utils/tool'

export default {
  mixins: [Common],
  components: {
    ClPaymentNew,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      showFilter: false,
      // data
      uuid_eq: null,
      freeword: null,
      status_eq_any: [],
      statusOptions: [
        { text: '支払い予約中', value: "reserved" },
        { text: '支払い済み', value: "paied" },
        { text: '返金済み', value: "canceled" },
        { text: '支払い失敗', value: "fail" },
        { text: '支払い改修済み', value: "fix_paied" },
        { text: '返金失敗', value: "cancel_fail" },
      ],
      created_at_gteq: null,
      created_at_lteq_end_of_day: null,

      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentClPayments: [],
    }
  },
  async mounted() {
    let self = this
    // filter
    if(self.filter) self.status_eq_any = [self.filter]
    this.search()
  },
  watch: {
    cl_payment_at (val, old) {
      console.log("list cl_payment_at")
      this.search()
    }
  },  
  computed: {
  },
  methods: {
    async clear() {
      this.freeword = null
      this.status_eq_any = []
      this.created_at_gteq = null
      this.created_at_lteq_end_of_day = null
      this.order = "desc"
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this

      // set params
      let params = {
        "q[uid_or_client_name_or_client_uid_or_orders_name_cont]": self.freeword,
        "q[created_at_gteq]": self.created_at_gteq,
        "q[created_at_lteq_end_of_day]": self.created_at_lteq_end_of_day,
        "status_eq_any": self.status_eq_any,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
        order: self.order,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/cl_payments.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentClPayments = res.data.data.cl_payments
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
    cancel(cl_payment){
      let self = this
      self.$bvModal.msgBoxConfirm("※オンライン支払いの場合は払い戻されます", {
        title: 'キャンセルしてよろしいですか？',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
          .delete(`/cmp/floor/cl_payments/${cl_payment.uid}.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                await self.search()
                self.$emit("canceled")
                window.storeCtl.commit("alert/setSuccess", "処理しました")
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
    retry(cl_payment){
      let self = this
      self.$bvModal.msgBoxConfirm("再実行していいですか？", {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
          .put(`/cmp/floor/cl_payments/${cl_payment.uid}/retry.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                await self.search()
                window.storeCtl.commit("alert/setSuccess", "処理しました")
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
  }
}
